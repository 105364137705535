.header {
  display: flex;
  align-items: center;
  width: 100%;
  gap:20px;
  padding: 0 20px;


  .logo {
    display: flex;
    align-items: center;
    font-size: 48px;
    font-weight: bold;
    gap: 5px;
  }

  .search {
    flex-grow: 1;
    input {
      font-size: 25px;
      width: 100%;
      height: 50px;
      border-radius: 25px;
      padding: 0 15px;
    }
  }

  .add {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 3px solid black;
    cursor: pointer;
    svg {
      width: 75%;
      height: 75%;
    }
  }

  .profile {
    width: 50px;
    height: 50px;
    cursor: pointer;
    img {
      width: 100%;
      border-radius: 25px;
      border: 2px solid black;
    }

    .menu {
      position: absolute;
      right: 20px;
      display: flex;
      flex-direction: column;
      gap:8px;
      align-items: flex-end;
      visibility: hidden;
      box-shadow: 0 2px 4px black;
      padding: 5px;
      background: white;
      z-index: 1;

      &.show {
        visibility: unset;
      }
    }
  }

  .notifications {
    position: absolute;
    top: 10px;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    align-items: center;

    .notification {
      border-radius: 100px;
      color: white;
      font-weight: bold;
      padding: 8px 16px;

      &.warning {
        background-color: darkorange;
      }

      &.error {
        background-color: darkred;
      }
    }


  }
}