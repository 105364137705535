.dialog {
  background: rgba(#606060, 70%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    position: absolute;
    right: 3px;
    top: 3px;
    cursor: pointer;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y:scroll;
    border-radius: 20px;
    padding: 20px;
    background: white;

    .prompt {
      font-size: 26px;
      font-weight: bold;
    }

    .project-title {
      font-weight: bold;
    }

    .buttons {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
      align-self: flex-end;
      align-items: flex-end;

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        width: 100px;
        height: 40px;
        cursor: pointer;

        &.cancel {
          color: white;
          background: #606060;
        }

        &.create {
          color: black;
          background: lightgreen;

          &.disabled {
            opacity: 50%;
          }
        }
      }
    }
  }

  .loader {
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: none;

    &.loading {
      display: flex;
    }
  }
}